.center {
    margin-left: 8%;
    margin-right: 8%;
    height: 400px;
}

.center h1 {
    font-size: 2.5em;
    text-align: center;
}

.center h2 {
    padding-top: 2em;
    font-size: 1.8em;
}

.link-list {
    font-size: 1.5em;
}

.link-item {
    margin-bottom: 10px;
}

.link-item a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.link-item a:hover {
    text-decoration: underline;
}

.txtFinal {
    padding: 0 10rem;
    text-align: center;
    font-style: oblique;
}

@media (max-width: 600px) {
    .center {
        margin-left: 5%;
        margin-right: 5%;
        height: auto;
    }

    .center h1 {
        font-size: 2em;
    }

    .center h2 {
        font-size: 1.5em;
    }

    .link-list {
        font-size: 1.2em;
    }

    .txtFinal {
        padding: 0 2rem;
    }

    @media (max-width: 400px) {
        .center {
            margin-left: -10%;
            margin-right: -10%;
        }

        .txtFinal {
            padding: 0 1rem;
        }
    }
}