.faceIcon{
  width: 40px;
  height: 40px;
}

.presentation{
  width: 100%;
  height: 60%;
}

.activitys{
  height: 400px;
  display: flex;
  margin-bottom: 50px;
}

.activity{
  width: 300px;  
  margin-left: auto;
  margin-right: auto;
  /* border: 1px black solid;  */
  text-align: center;
}

.activity div{
  /* border: 1px black solid;  */
  text-align: center;
}

.border-yellow{
  border-width: 5px;
  border-style: solid;
  border-color: #FFDF0D
}

.border-green{
  border-width: 5px;
  border-style: solid;
  border-color: green
}

.border-blue{
  border-width: 5px;
  border-style: solid;
  border-color: blue
}

.border-red{
  border-width: 5px;
  border-style: solid;
  border-color: red
}

.marginLeft{
  margin-right: 50px;
}

.imageWH{
  height: 175px;
  width: 175px;
}

.history{
  /* border: 1px black solid; */
  display: flex;
  flex-direction: row-reverse;
  height: 500px;
  width: 100%;
}

.historyImage{
  width: 650px;
  height: 400px;
  padding-left: 20px;
  margin-top: 35px;
}

.rounded{
  border-radius: 500px;
}

.historyText{
  padding-right: 20px;
  padding-left: 20px;
  max-width: 680px;
  min-width: 500px;
  text-align: justify;
  margin-top: 10px;
}

.activityTitle{
  margin: 10px;
}

.font-bold-custom{
  font-family: 'fonteAppae';
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 50px;
}

.activityText{
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  font-size: 15px;
}

#tituloHistoria{
  font-family: 'fonteAppae';
}

.button_doar_container{
  text-align: center;
  /* border: 1px solid black; */
  margin: 30px 0px 100px 0px;
}

.button_doar{
  font-size: 1em;
  border: none;
  height: 35px;
  width: 100px;
  background: #283A85;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  transition: 0.2s;
}

.button_doar:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px #21368a;
    transform: translateY(-4px);
}

@font-face{
  font-family: 'fonteAppae';
  src: url('../fonte/rockoultraflf.ttf');
}

@media screen and (max-width: 1100px){

  .history{
    display: block;
    /* margin-left: 10%; */
    text-align: center;
    height: 800px;
  }

  .historyText{
    /* width: 1500px; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    /* border: 1px black solid; */
    text-align: justify;
    max-width: 800px ;
  }
  
  .historyImage{
    /* margin-left: auto; */
    padding-left: 0px;
    width: 800px;
  }

  #tituloHistoria{
    text-align: center;
  }
}

@media screen and (max-width: 900px){
  .activitys{
    display: block;
    height: 750px;
  }

  .activity{
    float: left;
    margin-left: 75px;
    margin-bottom: 20px;
  }

  .historyText{
    margin-left: 5%;
  }

  .historyImage{
    width: 90%;
  }

}

@media screen and (max-width:772px){
  .activitys{
    height: 800px;
  }

  .activity{
    float: left;
    display: flex;
    margin-left: 20%;
    margin-right: 0px;
  }

  .history{
    height: 850px ;
  }

  .historyImage{
    /* width: 70%; */
    height: 350px;
  }

  .activity div{
    margin-left: 20px;
  }
  
}

@media screen and (max-width: 620px) {
  header{
    display: block;
    direction: initial;
    padding-right: 10px;
  }

  header div{
    text-align: center;
  }

  .logo{
    width: 320px;
    height: 150px;
  }

  .activitys{
    display: block;
    margin-left: 5px;
    height: 100%;
  }

  .activity{
    display: block;
    float: none;  
    margin-left: auto;
    margin-right: auto;
  }

  .activityText{
    margin-left: 5px;
  }

  .activityTitle{
    margin-left: 0px;
  }

  .history{
    height: 100%;
  }

  .historyText{
    min-width: 100px;
    margin-left: 0px;
  }
  
  .historyImage{
    height: 250px;
    /* padding-bottom: 500px; */
  }
  /* .historyImage{
    margin-left: 0px;
    padding-left: 0px;
  } */
}

@media screen and (max-width: 400px) {

  header{
    display: block;
    direction: initial;
    padding-right: 10px;
  }
  
  .logo{
    width: 100%;
    height: 150px;
  }
  
  .address{
    width: 100%;
    text-align: justify;
    align-self: flex-end;
    margin-bottom: 45px;
  }

  .activity{
    width: 300px;
    display: block;
    float: none;  
  }

  .history{
    display: block;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
  }

  .historyText{
    margin: 0px;
    text-align: justify;
    min-width: 100px;
  } 
  
  #tituloHistoria{
    text-align: center;
  }
}