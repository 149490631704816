body {
    padding: 0;
    margin: 0;
}

.center {
    margin-left: 8%;
    margin-right: 8%;
    display: inline-block;
}

.center h1 {
    font-size: 2.5em;
    text-align: center;
}

.center p {
    text-align: justify;
}

.imgSobre {
    margin-left: 8%;
    margin-right: 8%;
}

.imgSobre img {
    width: 100%;
}

.txtFinal {
    padding: 0 10rem;
    text-align: center;
    font-style: oblique;
}

@media screen and (max-width: 400px) {
    .center {
        margin-left: 0;
        margin-right: 0;
        padding-left: 50px;
        padding-right: 50px;
    }

}